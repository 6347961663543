@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-Demi.woff2") format("woff2"),
    url("AvenirNextLTPro-Demi.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-Bold.woff2") format("woff2"),
    url("AvenirNextLTPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Avenir Next LT Pro";
  font-weight: 500;
  margin: 0;
  background-color: #ffffff;
  overflow-x: hidden !important;
}

@media screen and (max-width: 600px) {
  .row {
    padding-right: 0px !important;
  }
}
