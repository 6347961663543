.formDiv {
  background-color: white;
  border-radius: 5px;
  padding: 40px;
}

.form_section_row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.form_section {
  min-height: 950px;
  /* width: 100%; */
  background-color: #475bd8;
}

.form_section_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 96px;
  padding-bottom: 80px;
}

.form_heading {
  font-size: 48px;
  color: #fff;
  padding: 0px 12px;
  font-weight: bolder;
}

.iElement {
  width: 45%;
  position: absolute;
  margin-top: 40px;
}

.formdiv_heading {
  font-size: 30px;
  font-family: "Nunito";
  font-weight: 900;
}

.formdiv_subtext {
  font-family: "Nunito";
  font-size: 12.8px;
  color: #4b5563ba;
  line-height: 17px;
  margin: 0px;
  margin-top: 16px;
}

.form-floating {
  font-family: "Nunito";
  font-weight: bolder;
}

.form-control {
  border: transparent !important;
  border-bottom: 1px solid lightgray !important;
  transition-duration: 0.3s !important;
  border-radius: 0px !important;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #475bd8 !important;
}

.form-floating label {
  color: gray;
  font-size: 13.6px;
  padding-left: 0px !important;
  transition-duration: 0.3s !important;
}

.form-floating label:focus {
  color: #475bd8;
}

.getdemobtn {
  outline: none;
  border: transparent;
  border-radius: 5px;
  font-size: 19.2px;
  background-color: #5667ff;
  color: white;
  font-weight: 800;
  width: 100%;
  height: 48px;
  text-align: center;
  margin: 0px;
  margin-top: 8px;
}

@media screen and (max-width: 982px) {
  .iElement {
    display: none;
  }
  .getdemobtn {
    height: 58px;
  }
}

@media screen and (max-width: 500px) {
  .form_heading {
    font-size: 27px;
    margin-bottom: 40px;
    padding: 0px;
  }
  .formDiv {
    padding: 20px;
  }
  .form_section_div {
    padding-top: 70px;
  }
  .form_section {
    min-height: 750px;
    /* width: 100%; */
    background-color: #475bd8;
  }
}

@media screen and (min-width: 1650px) {
  .form_section_row {
    width: 80%;
    margin: auto;
  }
}


.showAfter608 {
  display: block;
}

@media screen and (min-width: 608px) {
  .showAfter608 {
    display: none;
  }
}