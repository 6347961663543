.footer_top {
  display: flex;
  padding: 60px;
  width: 100%;
  flex-direction: column;
  justify-items: center;
  align-content: center;
  position: relative;
  z-index: 2;
}

.footer_address {
  margin: 34px;
  font-size: 18px;
  text-align: center;
}

.purpleLogo {
  width: 233px;
}

.social_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.social_icons a {
  padding: 26px;
  display: flex;
  align-items: center;
  margin: 5px 15px;
  border-radius: 100%;
  text-decoration: none;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  color: #000;
}

.social_icons a ion-icon {
  font-size: 32px;
}

.privacyPolicy {
  margin-top: 48px;
  text-align: center;
  color: #475bd8;
}

.copyright_text {
  text-align: center;
  font-size: 18px;
  color: white;
  margin: 0px;
}

.footer_copyright {
  background-color: #475bd8;
  padding: 30px 0px;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 450px) {
  .social_icons a ion-icon {
    font-size: 24px;
  }
  .social_icons a {
    margin: 5px 8px;
    padding: 18px;
  }
  .footer_address {
    font-size: 15px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
