.quoteBox {
  background-color: rgb(227, 227, 248);
  min-height: 215px;
}
.mobile {
  border: 10px solid #222;
  border-radius: 20px;
  width: 235px;
}

.clientName {
  font-weight: lighter;
  font-family: "Courier New", Courier, monospace;
  padding: 0px 8px;
  font-size: 15px;
  margin-bottom: 0px;
}

.personName {
  font-size: 20px;
  margin-bottom: 0px;
  color: rgb(72, 72, 153);
  padding: 0px 8px;
}

.clientimg {
  min-height: 150px;
}

@media screen and (max-width: 1200px) and (min-width: 1090px) {
  .quoteBox {
    background-color: rgb(227, 227, 248);
    min-height: 175px;
  }
  .mobile {
    width: 200px;
  }
}
