.faq_question {
  font-size: 19.2px;
  margin: 0px;
  padding: 18px;
  -webkit-appearance: none;
}

.faq_ans {
  font-family: "Nunito";
  margin: 0px 0px 18px 20px;
}

.ques {
  cursor: pointer;
}

.ques ion-icon {
  font-size: 30px !important;
  font-weight: bolder;
}

.faq_heading_main {
  font-size: 36px;
  font-weight: bolder;
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .faq_question {
    width: 85%;
  }
}

@media screen and (max-width: 500px) {
  .faq_heading_main {
    font-size: 30px;
  }
}

@media screen and (min-width: 1650px) {
  .accordion {
    width: 70%;
    margin: auto;
  }
}
