.testimonials {
  min-height: 700px;
  width: 100%;
  /* background: url("bg1.webp"), rgba(71, 91, 216, 0.95); */
  background: linear-gradient(rgba(71, 91, 216, 0.95), rgba(71, 91, 216, 0.95)),
    url("bg1.webp");
  display: flex;
  align-items: center;
}

.testimonialsMainDiv {
  display: flex;
  align-items: center;
  width: 85%;
  justify-content: center;
  margin: auto;
}

.overlay {
  width: 100vw;
  min-height: 110vh;

  background-color: rgba(71, 91, 216, 0.95);
  position: absolute;
}

.testimonial_content {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: auto;
}

.testimonials_left {
  width: 100%;
  text-align: left;
  padding: 130px 12px;
}

.testimonials_right {
  width: 60%;
}

.testimonial_content {
  position: relative;
}

.midMaintext {
  font-size: 36px;
  font-weight: bold !important;
  color: white;
}

.yellow {
  color: #fed063;
}

@media screen and (max-width: 1090px) {
  .testimonialsMainDiv {
    flex-direction: column;
  }
  .testimonial_content {
    width: 98%;
  }
  .testimonials_left {
    padding-bottom: 48px !important;
  }
}

@media screen and (max-width: 1429px) {
  .testimonialsMainDiv {
    width: 100%;
  }
}
