.navbar {
  background-color: transparent;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  z-index: 10;
  padding: 0px 30px;
  background-color: #fff;
  transition-duration: 0.3s;
  height: 80px;
  position: relative;
  z-index: 20;
}

.navbarScrolled {
  background-color: #fff;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  height: 65px;
  z-index: 20;
  padding: 0px 30px;
  transition-duration: 0.3s;
  height: 100px;
}

.logoImage {
  width: 160px;
  transition-duration: 0.3s;
}

.logoImageScrolled {
  width: 104px;
  transition-duration: 0.3s;
}

.navlist {
  display: flex;
  list-style: none;
  color: #2D2D2D;
}

.navlist li {
  margin: 5px 10px;
  cursor: pointer;
  border-bottom: 2px solid #475bd8;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.navlist li:hover {
  border-bottom: 2px solid white;
}

.navmobile {
  color: white;
  font-size: 21px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  transition-duration: 0.3s;
}

.navmobileScrolled {
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  transition-duration: 0.3s;
}

.navmobileScrolled ion-icon {
  font-size: 20px;
  color: white;
  margin: 0px 7px 4px 7px;
}

.navmobile ion-icon {
  font-size: 24px;
  color: white;
  margin: 0px 7px 4px 7px;
}
/* //////////////////////////////////////////////////////////////////////////////// */
.mobnavcontainer {
  position: fixed;
  /* display: none; */
  top: 15px;
  left: 2.5%;
  width: 95%;
  background-color: rgb(51, 69, 185);
  color: white;
  padding: 10px 0px;
  transition-duration: 0.4s;
  overflow: hidden;
  max-height: 0px;
  z-index: 100;
  /* transition: max-height 0.25s ease-in; */
}

.withHeight {
  max-height: 500px !important;
}

.mobnavcontainer ul {
  list-style: none;
  color: white;
}

.mobnavcontainer ul li {
  cursor: pointer;
  margin: 10px 0px;
  cursor: pointer;
  border-bottom: 2px solid #475bd8;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}
.mobnavcontainer ul li:hover {
  border-bottom: 2px solid #fff;
}

.mobilenavicon ion-icon {
  color: #475bd8;
  position: relative;
  font-size: 40px;
  z-index: 101;
}

/* //////////////////////////////////////////////////////////////////////////////////// */

@media screen and (max-width: 700px) {
  .navlist {
    display: none;
  }
  .navmobileScrolled {
    display: none;
  }
  .navmobile {
    display: none;
  }
  .navbar {
    padding: 0px 15px;
  }
}

@media screen and (min-width: 700px) {
  .mobilenavicon {
    display: none;
  }
  .mobnavcontainer {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .navlist li {
    font-size: 22px;
  }
}
