.tab {
  width: 390px !important;
  text-align: left;
  border: 1px solid lightgray;
  background-color: white;
  padding: 26px 16px 16px 16px;
  min-height: 195px;
}

.tabs {
  width: 390px !important;
  border: 1px solid lightgray;
  border-bottom: 0px;
  background-color: white;
  margin-bottom: 0px !important;
}

.tab_heading {
  font-size: 28px;
}

.font-text {
  font-size: 16px;
}

.tabs-container {
  margin-left: -423px;
  position: relative;
}

.arrows {
    position: relative;
    z-index: 4;
    bottom: 45px;
    left: 315px;
    color: #475bd8;
}

.arrows ion-icon {
  font-size: 29px;
  margin: 2px;
}

@media screen and (max-width: 500px) {
  .tabs {
    width: 355px !important;
  }
  .arrows {
    left: 270px;
  }
  .tab {
    width: 355px !important;
    min-height: 205px;
  }
  .tabs-container {
    margin-left: -375px;
    position: relative;
  }
}
