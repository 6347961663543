.topArrow {
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 100%;
  padding: 25px;
  outline: none;
  border: transparent;
  color: #475bd8;
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: center;
}

.topArrow ion-icon {
  font-family: 24px;
  color: #475bd8;
}
