.numbersBox {
  display: flex;
  padding-top: 120px;
  /* width: 100%; */
  padding-bottom: 80px;
  /* justify-content: space-around; */
  align-items: center;
  background-color: #f5f6ff;
}
.numbersBox div {
  text-align: center;
}
.midLeft {
  text-align: left;
}
.bigText {
  color: #475bd8;
  font-size: 40px;
  font-weight: 800;
  margin: 0px;
}
.cubeImage {
  width: 440px;
}
.normalText {
  font-size: 24px;
  margin: 0px;
  font-weight: bolder;
}
.mainColor {
  color: #475bd8;
}
.midSection {
  display: flex;
  text-align: center;
  justify-content: center;
  /* width: 100%; */
  padding: 80px 10px;
}
.midSection2 {
  display: flex;
  text-align: center;
  justify-content: center;
  /* width: 100%; */
  padding: 80px 10px;
}
.midSection_left {
  width: 60%;
  text-align: left;
  padding: 0px 15px;
}
.midSection_right {
  width: 40%;
  padding: 0px 15px;
}
.midMaintext {
  font-size: 48px;
  margin-top: 0px;
  font-weight: bold;
  line-height: 54px;
}
.midMaintext2 {
  font-size: 36px;
  font-weight: 800;
  margin-top: 0px;
  margin-left: 50px;
}
.createMyAppButton {
  outline: none;
  border: transparent;
  border-radius: 5px;
  font-size: 32px;
  background-color: #ff7a03;
  color: white;
  font-weight: 800;
  width: 412px;
  height: 72px;
  text-align: center;
  margin: 30px 0px;
}
.smallFeatureText {
  margin-bottom: 0px;
  margin-left: 50px;
  font-weight: 800;
  color: #475bd8;
}
.numbers_mediumText {
  font-size: 32px;
  font-weight: bold;
}

.numbers_smallText {
  font-size: 24px;
  font-weight: bold;
}



@media screen and (max-width: 991px) {
  .midLeft {
    text-align: center;
  }
}

@media screen and (max-width: 501px) {
  .createMyAppButton {
    font-size: 24;
    width: 100%;
  }
  .midLeft {
    text-align: left;
  }
  .midMaintext2 {
    line-height: 54px;
    margin-left: 0px;
  }
  .midMaintext {
    line-height: 54px;
  }
  .smallFeatureText {
    margin-left: 0px;
  }
  .midSection {
    width: 100%;
  }
  .midSection2 {
    width: 100%;
  }
  .numbers_mediumText {
    line-height: 35px;
    font-weight: lighter;
    margin: 30px 0px;
  }
  .cubeImage {
    width: 390px;
  }
}

@media screen and (min-width: 1601px) {
  .midMaintext2,
  .midMaintext {
    line-height: 55px;
  }
}

@media screen and (max-width: 786px) {
  .midSection2 {
    flex-direction: column-reverse;
  }
  
}

@media screen and (max-width : 500px) {
  .MobileWidth {
    width: 85%
  }
}
