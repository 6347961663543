.header {
  /* width: 100%; */
  min-height: 472.5px;
  height: calc(70vh);
  background-color: #475bd8;
  display: flex;
  margin-top: -3px;
}
.leftEmpty {
  width: 40%;
  height: 40vh;
}
.rightEmpty {
  width: 60%;
  /* height: calc(70vh); */
  padding: 0px 30px;
  display: flex;
  align-items: flex-end;
  /* margin-top: 89px; */
}
.logoSymbolImageDiv {
  align-self: flex-end;
}
.logoSymbolImageDiv img {
  margin-top: -180px;
  left: -45px;
  position: absolute;
}
.mobileImageDiv img {
  margin-top: 65px;
  position: absolute;
  left: 230px;
  resize: both;
  width: 265px;
}
.centerTextDiv {
  color: white;
}
.smallText {
  font-size: 16px;
}
.smallText ion-icon {
  margin: 4px;
  font-size: 18px;
  margin-bottom: -2px;
  color: #fed063;
}
.centerTextMain {
  font-size: 48px;
  margin: 0px;
  margin-top: 16px;
  font-weight: 900;
  line-height: 65px;
}
.priceBox {
  padding: 5px 10px;
  background-color: white;
  color: #475bd8;
  border-radius: 5px;
  margin-left: 15px;
}
.freeDemoButton {
  outline: none;
  border: transparent;
  border-radius: 5px;
  font-size: 32px;
  background-color: #ff7a03;
  color: white;
  font-weight: 800;
  width: 442px;
  height: 64px;
  text-align: center;
  margin: 30px 0px;
  -webkit-animation: glowing 1500ms infinite;
  -moz-animation: glowing 1500ms infinite;
  -o-animation: glowing 1500ms infinite;
  animation: glowing 1500ms infinite;
}
.verticalTextDiv p {
  transform: rotate(270deg);
  color: white;
  font-weight: 900;
  opacity: 0.2;
  font-size: 65px;
  margin-top: 244px;
}
.verticalTextDiv {
  width: 10%;
  position: absolute;
  right: 20px;
  margin-bottom: 30px;
}

@media screen and (max-width: 1225px) {
  .mobileImageDiv img {
    left: 175px;
  }
  .leftEmpty {
    width: 35%;
  }
  .rightEmpty {
    width: 65%;
  }
}

@media screen and (max-width: 1150px) {
  .mobileImageDiv img {
    display: none;
  }
  .leftEmpty {
    width: 10%;
  }
  .rightEmpty {
    width: 90%;
  }
  /* .logoSymbolImageDiv img {
    display: none;
  } */
  /* .verticalTextDiv {
    display: none;
  } */
}

@media screen and (max-width: 750px) {
  .logoSymbolImageDiv img {
    display: none;
  }
  .verticalTextDiv {
    display: none;
  }
  .leftEmpty {
    width: 0px;
  }
  .rightEmpty {
    width: 100%;
    padding: 15px;
  }
}

@media screen and (max-width: 608px) {
  .centerTextMain {
    font-size: 27.4px;
    line-height: 40px;
  }
  .rightEmpty {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 80px;
  }
  .header {
    min-height: fit-content !important;
    height: auto;
  }
  .freeDemoButton {
    width: 100%;
    font-size: 22px;
  }
  .smallText {
    font-size: 11.2px;
  }
  .smallText ion-icon {
    font-size: 14px;
    margin-right: 2px;
    margin-left: 2px;
  }
}

.mobileImageDiv {
  display: flex;
  align-items: flex-end;
}

@media screen and (min-width: 1601px) {
  .mobileImageDiv img {
    width: 25rem;
  }
  .header {
    min-height: 740px;
    max-height: 756px;
  }
  .rightEmpty {
    min-height: 740px;
    max-height: 756px;
  }
}

@media screen and (min-width: 1000) {
  .rightEmpty {
    min-height: 450px;
  }
}

@media screen and (min-width: 1850px) {
  .centerTextMain {
    font-size: 60px;
    line-height: 76px;
  }
  .smallText {
    font-size: 30px;
  }
  .smallText ion-icon {
    font-size: 30px;
  }
  .freeDemoButton {
    font-size: 40px;
    width: 542px;
    height: 84px;
  }
  .verticalTextDiv p {
    font-size: 100px;
    margin-bottom: 35px;
  }
  .logoSymbolImageDiv img {
    width: 250px;
    margin-top: -226px;
  }
}

@-webkit-keyframes glowing {
  0% {
    background-color: #ff7a03;
    -webkit-box-shadow: 0 0 3px #ff7a03;
  }
  50% {
    background-color: rgb(255, 119, 0);
    -webkit-box-shadow: 0 0 40px rgb(255, 119, 0);
  }
  100% {
    background-color: #ff7a03;
    -webkit-box-shadow: 0 0 3px #ff7a03;
  }
}

@-moz-keyframes glowing {
  0% {
    background-color: #ff7a03;
    -moz-box-shadow: 0 0 3px #ff7a03;
  }
  50% {
    background-color: rgb(255, 119, 0);
    -moz-box-shadow: 0 0 40px rgb(255, 119, 0);
  }
  100% {
    background-color: #ff7a03;
    -moz-box-shadow: 0 0 3px #ff7a03;
  }
}

@-o-keyframes glowing {
  0% {
    background-color: #ff7a03;
    box-shadow: 0 0 3px #ff7a03;
  }
  50% {
    background-color: rgb(255, 119, 0);
    box-shadow: 0 0 40px rgb(255, 119, 0);
  }
  100% {
    background-color: #ff7a03;
    box-shadow: 0 0 3px #ff7a03;
  }
}

@keyframes glowing {
  0% {
    background-color: #ff7a03;
    box-shadow: 0 0 3px #ff7a03;
  }
  50% {
    background-color: rgb(255, 119, 0);
    box-shadow: 0 0 40px rgb(255, 119, 0);
  }
  100% {
    background-color: #ff7a03;
    box-shadow: 0 0 3px #ff7a03;
  }
}
/* ============================================================================================ */
.banner {
  text-align: center;
  background: linear-gradient(180deg, #f8f9fc, #e5e7f1);
  padding: 40px 20px;
  border-radius: 10px;
  height: calc(100vh - 80px);
  margin-top: 0px;
}

.heading {
  font-size: 2rem;
  color: #1a1a1a;
  font-family: "Montserrat";
}

.highlight {
  color: #a254f2;
  font-family: "Montserrat";
  font-weight: bold;
  background: linear-gradient(
    270deg,
    #475bd8 6.1%,
    #ca11ab 60.94%,
    #475bd8 93.75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ownApp {
  color: #a254f2;
  font-weight: bold;
  font-family: "Montserrat";
  position: relative;
  background: linear-gradient(
    270deg,
    #475bd8 6.1%,
    #ca11ab 60.94%,
    #475bd8 93.75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ownApp::after {
  content: "";
  position: absolute;
  left: -5px;
  right: -5px;
  bottom: -5px;
  height: 5px;
  background: rgba(162, 84, 242, 0.4);
  border-radius: 5px;
}

.subtext {
  font-size: 1rem;
  color: #5a5a5a;
  font-weight: 400;
  margin: 22px 0px;
  font-family: "Montserrat";
}

.buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 10;
}

.primaryButton {
  background-color: #5667ff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  margin: 20px 30px;
}

.primaryButton:hover {
  background-color: #4055cc;
}

.link {
  color: #5667ff;
  font-size: 1rem;
  text-decoration: none;
  margin: 20px 30px;
}

.link:hover {
  text-decoration: underline;
}

.heroImg {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
 
 width: 1200px;
}

@media screen and (max-width: 500px) {
  .link {
    display: none;
  }
  .heroImg {
    min-width: 900px;
  }
}

.cloudText {
  border: 1.944px dashed #0e132c;
  background: #e3e7ff;
  position: absolute;
  z-index: 15;
  border-radius: 20px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  /* filter: blur(6.219977855682373px); */
}

.pos1 {
  top: 58%;
  left: 24.5%;
  padding: 14px;
}

.pos2 {
  top: 77%;
  left: 63%;
  padding: 14px;
}


@media screen and (max-width: 973px) {
    .pos1 {
      left: 18%;
      
    }
}
@media screen and (max-width: 720px) {
  .pos1 {
    left: 3%;
    padding: 10px;
  }
  .pos2 {
    padding: 10px;
    left: 57%
  }
}


@media screen and (max-height: 790px) and (max-width: 500px) {
  .heroImg {
    width: 820px;
  }
}


@media screen and (max-height: 750px) {
  .heroImg {
    width: 1020px;
  }
}

@media screen and (max-height: 690px) {
  .heroImg {
    width: 950px;
  }
}

@media screen and (max-height: 659px) {
  .heroImg {
    width: 850px;
  }
}


@media screen and (max-height: 650px) {
  .heroImg {
    width: 650px;
  }
}

