.owl-dots {
  margin-top: 10px !important;
  color: rgba(71, 91, 216, 1);
}

.item {
  display: flex;
  justify-content: center;
}
.owl-nav {
  display: none;
}

.singleMobile {
  display: none;
}
.doubleMobile {
  display: none
}
.trippleMobile {
  display: none
}

@media screen and (max-width: 583px) {
  .singleMobile {
    display: block;
  }
  .doubleMobile{ display: none }
  .trippleMobile { display: none }
}

@media screen and (max-width: 890px) and (min-width: 583px) {
  .doubleMobile {
    display: block;
  }
  .singleMobile {
    display: none;
  }
  .trippleMobile {
    display: none;
  }
}

@media screen and (min-width: 890px) {
  .trippleMobile {
    display: block;
  }
  .singleMobile {
    display: none;
  }
  .doubleMobile {
    display: none;
  }
}

.popup{
  animation: popup 1s;
}

.element1 {
  opacity: 0;
}

@keyframes popup {
  0%{
    transform: scale(0);
  }
  50%{
    transform: scale(0.2);
  }
  60%{
    transform: scale(0.4);
  }
  70%{
    transform: scale(0.6);
  }
  80%{
    transform: scale(0.8);
  }
  90%{
    transform: scale(0.9);
  }
  100%{
    transform: scale(1);
  }
}