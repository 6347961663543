.f03body {
    font-family: Arial, sans-serif;
    text-align: center;

    background-color: #009ece;
    color: #333;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    font-family: 'Montserrat';
}
.f03h1 {
    font-size: 5rem;
    margin: 0;
    color: #fff;
}
.f03p {
    font-size: 1.5rem;
    margin: 0px 0;
    color: #fff
}
.f03h3 {
    font-size: 2rem;
    margin-bottom: 5px;
    color: white;
}
