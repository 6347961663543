.video_heading {
  font-size: 36px;
  font-weight: bolder;
  line-height: 43px;
  color: #475bd8;
}

.video_section {
  /* width: 100%; */
  display: flex;
  justify-content: space-around;
}

.video_subHeading {
  font-size: 24px;
  margin-bottom: 0px;
}

.video_createButton {
  outline: none;
  border: transparent;
  border-radius: 5px;
  font-size: 32px;
  background-color: #ff7a03;
  color: white;
  font-weight: 800;
  width: 412px;
  height: 72px;
  text-align: center;
  margin: 20px 0px;
}

.left ion-icon {
  color: #fed063;
  font-size: 32px;
  margin: 0px 3px;
}

.video_footer {
  background-color: #475bd8 !important;
  display: flex;
  justify-content: center;
}

.video_footer_heading {
  color: white;
  font-size: 38px;
  font-weight: bolder;
}

.video_footer_heading span {
  color: #fed063;
}

.video_createBrand {
  outline: none;
  border: transparent;
  border-radius: 5px;
  font-size: 24px;
  background-color: #ff7a03;
  color: white;
  font-weight: 800;
  width: 258px;
  height: 63px;
  text-align: center;
  margin: 0px;
}

.padding {
  padding-top: 120px;
  padding-bottom: 90px;
  /* width: 100%; */
}

.plyrcomponent {
  width: 100%;
  margin: auto;
}

@media screen and (max-width: 991px) {
  .left {
    text-align: center;
  }
  .video_createButton {
    margin-bottom: 60px;
  }
  .video_footer_heading {
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .video_section {
    width: 100%;
  }
  .left {
    text-align: left;
  }
  .video_createBrand {
    width: 90%;
    font-size: 20px;
  }
  .video_createButton {
    width: 100%;
  }
  .video_footer_heading {
    font-size: 24px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 1300px) {
  .padding {
    width: 80%;
    margin: auto;
  }
}
